import { trackEvent, trackPageViewV2 } from "@/services/analytics-adapter";
import { overrideDefaultLinkStyling } from "@/theme";
import { convertPxToRem, buildMapLinkURL } from "@/utils";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import IconLoader from "@/features/Common/components/IconLoader";

const EMERGENCY_PHONE_NUMBER_DIALING_CODE_ZA = "24";
const EMERGENCY_PHONE_NUMBER_ZA = "084 124";
const EMERGENCY_PROVIDER_ZA = "ER24";

export default function MedicalAssistanceFindAHospital() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onUrgentCareClick() {
    trackEvent({
      event: "action.urgentCarePhoneNumber",
      source: "symptom assessment risk level 6",
    });
  }

  function onBackToHomeButtonClick() {
    navigate("/home");

    trackEvent({
      event: "action.declineFindAHospital",
      source: "Medical assistance find a hospital",
    });
  }

  function onContinueButtonClick() {
    trackEvent({
      event: "action.findAHospital",
      source: "Medical assistance find a hospital",
    });
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Find a hospital",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Find a hospital",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <>
      <Stack
        justifyContent="flex-end"
        spacing={2}
        sx={{
          height: "100%",
        }}
      >
        <Stack
          justifyContent="flex-end"
          color="primary.contrastText"
          spacing={4}
          p={2}
        >
          <IconLoader
            icon="ChatBubbleWithFaceIcon"
            color="primary"
            sx={{
              fontSize: convertPxToRem(150),
              alignSelf: "center",
            }}
          />

          <Stack textAlign="center" spacing={4} py={4}>
            <Typography variant="h1" color="inherit">
              {t("MedicalAssistanceFindAHospital.title")}
            </Typography>

            <Typography variant="h3" color="inherit">
              {t("MedicalAssistanceFindAHospital.subTitle")}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Button
              onClick={onBackToHomeButtonClick}
              color="info"
              variant="outlined"
              fullWidth
            >
              {t("common.noThankYou")}
            </Button>
            <Button
              onClick={onContinueButtonClick}
              href={buildMapLinkURL(`Hospital near me`)}
              target="_blank"
              color="info"
              fullWidth
            >
              {t("common.yesPlease")}
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          py={3}
          px={1}
          bgcolor="background.paper"
          textAlign="center"
          alignItems="center"
          onClick={onUrgentCareClick}
          component="a"
          href={`tel:${EMERGENCY_PHONE_NUMBER_ZA}`}
          target="_self"
          sx={{
            ...overrideDefaultLinkStyling,
            cursor: "pointer",
          }}
        >
          <Stack direction={"row"} gap={1} alignItems="center">
            <Typography variant="h4" color="neutral.dark">
              {t("MedicalAssistance.urgentCare.title")}
            </Typography>

            <Stack
              direction={"row"}
              alignSelf="center"
              alignItems="center"
              color="primary.main"
            >
              <IconLoader icon="PhoneIcon" />
              <Typography variant="h4" ml={0.5} color="inherit">
                {t("MedicalAssistance.urgentCare.phoneText", {
                  emergencyProvider: EMERGENCY_PROVIDER_ZA,
                })}
              </Typography>
            </Stack>
          </Stack>

          <Typography color="neutral.light" variant="body2">
            {t("MedicalAssistance.urgentCare.subText", {
              phoneNumber: `${EMERGENCY_PHONE_NUMBER_DIALING_CODE_ZA} ${EMERGENCY_PHONE_NUMBER_ZA}`,
            })}
          </Typography>
        </Stack>
      </Stack>

      <FullscreenBackground color="primary.main" />
    </>
  );
}
