import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import IconLoader from "@/features/Common/components/IconLoader";
import ServiceAvailabilityList from "@/features/Common/components/ServiceAvailabilityList";
import { trackEvent, trackPageViewV2 } from "@/services/analytics-adapter";
import { ProductAdministratorSupportedServiceChannelAvailability } from "@/services/core-api-adapter";
import theme, { overrideDefaultLinkStyling } from "@/theme";
import { convertPxToRem } from "@/utils";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const EMERGENCY_PHONE_NUMBER_DIALING_CODE_ZA = "24";
const EMERGENCY_PHONE_NUMBER_ZA = "084 124";
const EMERGENCY_PROVIDER_ZA = "ER24";

interface OutsideConsultationOperatingHoursProps {
  redirectUrl: string | undefined;
  availabilityData: ProductAdministratorSupportedServiceChannelAvailability[];
  shouldDisplayViewConsultationHistoryCTA?: boolean;
}

export default function OutsideConsultationOperatingHours({
  redirectUrl,
  availabilityData,
  shouldDisplayViewConsultationHistoryCTA = false,
}: OutsideConsultationOperatingHoursProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onUrgentCareClick() {
    trackEvent({
      event: "action.urgentCarePhoneNumber",
      source: "outside operating hours screen",
    });
  }

  function onBackToHomeButtonClick() {
    navigate("/home");
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Outside consultation operating hours",
      pageSubSection1: "Medical assistance",
      pageSubSection2:
        "Medical assistance:Outside consultation operating hours",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <>
      <Stack spacing={2}>
        <Stack
          justifyContent="flex-end"
          color="primary.contrastText"
          spacing={4}
          p={2}
          sx={{
            height: "100%",
          }}
        >
          <IconLoader
            icon="ChatBubbleWithTimerIcon"
            color="primary"
            sx={{ fontSize: convertPxToRem(128), alignSelf: "center" }}
          />
          <Stack textAlign="center" spacing={4} py={4}>
            <Typography variant="h1" color="inherit">
              {t("OutsideConsultationOperatingHours.title")}
            </Typography>

            <Typography variant="h3" color="inherit">
              {t("OutsideConsultationOperatingHours.subTitle")}
            </Typography>

            {shouldDisplayViewConsultationHistoryCTA && (
              <Typography variant="h3" color="inherit">
                {t(
                  "OutsideConsultationOperatingHours.activeConsultation.subTitle"
                )}
              </Typography>
            )}
          </Stack>

          <Stack pt={1} px={2} spacing={1} color={theme.palette.neutral[50]}>
            <Typography
              sx={{ color: (theme) => theme.palette.neutral[50] }}
              textAlign="center"
              variant="body2"
            >
              {t("OutsideConsultationOperatingHours.serviceAvailability.title")}
            </Typography>

            <ServiceAvailabilityList
              delimiter="-"
              sx={{
                color: theme.palette.neutral[50],
              }}
              data={availabilityData}
            />
          </Stack>

          <Stack direction="row" spacing={1}>
            <Button
              onClick={onBackToHomeButtonClick}
              color="info"
              variant={
                shouldDisplayViewConsultationHistoryCTA
                  ? "outlined"
                  : "contained"
              }
              fullWidth
              sx={{
                ...(shouldDisplayViewConsultationHistoryCTA === true && {
                  width: "50%",
                }),
              }}
            >
              {t("common.goHomeButton")}
            </Button>
            {shouldDisplayViewConsultationHistoryCTA && (
              <Button
                component="a"
                target="_blank"
                aria-label={t(
                  "OutsideConsultationOperatingHours.activeConsultation.continueButtonLabel"
                )}
                href={redirectUrl}
                color="info"
                fullWidth
              >
                {t(
                  "OutsideConsultationOperatingHours.activeConsultation.continueButtonLabel"
                )}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack
          direction={"column"}
          spacing={1}
          py={3}
          bgcolor="background.paper"
          width="100%"
          textAlign="center"
          alignItems="center"
          onClick={onUrgentCareClick}
          component="a"
          href={`tel:${EMERGENCY_PHONE_NUMBER_ZA}`}
          target="_self"
          sx={{
            ...overrideDefaultLinkStyling,
            cursor: "pointer",
          }}
        >
          <Stack direction={"row"} gap={1} alignItems="center">
            <Typography variant="h4" color="neutral.dark">
              {t("MedicalAssistance.urgentCare.title")}
            </Typography>

            <Stack
              direction={"row"}
              alignSelf="center"
              alignItems="center"
              color="primary.main"
            >
              <IconLoader icon="PhoneIcon" />
              <Typography variant="h4" ml={0.5} color="inherit">
                {t("MedicalAssistance.urgentCare.phoneText", {
                  emergencyProvider: EMERGENCY_PROVIDER_ZA,
                })}
              </Typography>
            </Stack>
          </Stack>

          <Typography color="neutral.light" variant="body2">
            {t("MedicalAssistance.urgentCare.subText", {
              phoneNumber: `${EMERGENCY_PHONE_NUMBER_DIALING_CODE_ZA} ${EMERGENCY_PHONE_NUMBER_ZA}`,
            })}
          </Typography>
        </Stack>
      </Stack>
      <FullscreenBackground color="primary.main" />
    </>
  );
}
