import { Box, Button, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import theme, {
  overrideDefaultLinkStyling,
  shadows,
} from "../../../../../../theme";
import {
  convertPxToRem,
  buildMapLinkURL,
  isMobileDevice,
} from "../../../../../../utils";
import DefaultAccordion from "../../../../../Common/components/DefaultAccordion";
import IconLoader from "../../../../../Common/components/IconLoader";
import WatermarkIcon from "../../../../../Common/components/WatermarkIcon";

interface FindADoctorResultsProps {
  doctorSearchResults: any[];
}

interface ResultsIconCounterProps {
  resultsLength: number;
}

function ResultsIconCounter({ resultsLength }: ResultsIconCounterProps) {
  return (
    <Box
      sx={{
        position: "relative",
        height: 35,
        width: 35,
        alignSelf: "center",
      }}
    >
      <IconLoader
        icon="HandDrawnCircleIcon"
        sx={{
          color: "accent2.light",
          fontSize: convertPxToRem(35),
          alignSelf: "center",
          position: "absolute",
          left: "0",
        }}
      />
      <Typography
        sx={{
          fontWeight: 700,
          position: "relative",
          zIndex: 1,
          top: "50%",
          left: "50%",
          textAlign: "center",
          transform: "translate(-50%, -50%)",
        }}
      >
        {resultsLength}
      </Typography>
    </Box>
  );
}

export default function FindADoctorResults({
  doctorSearchResults,
}: FindADoctorResultsProps) {
  const { t } = useTranslation();
  const [filteredTowns, setFilteredTowns] = useState<string[]>([]);

  function filterDoctorSearchResultsByTown(
    doctorSearchResults: any[],
    town: string
  ): any[] {
    return doctorSearchResults.filter(
      (result) => result.town?.toLowerCase() === town?.toLowerCase()
    );
  }

  function extractTownsFromDoctorSearchResults(doctorSearchResults: any[]) {
    const towns: string[] = [];

    doctorSearchResults.forEach((result) => {
      const town = result.town?.toLowerCase();
      if (town && !towns.includes(town)) {
        towns.push(town);
      }
    });

    return towns;
  }

  useMemo(() => {
    if (doctorSearchResults) {
      const filteredTownsFromDoctorSearchResults =
        extractTownsFromDoctorSearchResults(doctorSearchResults);
      setFilteredTowns(filteredTownsFromDoctorSearchResults);
    }
  }, [doctorSearchResults]);

  async function onShareDoctorInformationClick(doctorDetails: any) {
    const shareData = {
      text: `${doctorDetails.professionalDisplayName}\n${doctorDetails.facilityTel}\n${buildMapLinkURL(`${doctorDetails.latitude},${doctorDetails.longitude}`)}`,
    };

    if (navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
      } catch (error: any) {
        console.log(`Error: ${error.message}`);
      }
    }
  }

  function buildDoctorName(doctor: any) {
    const {
      professionalTitle,
      professionalName,
      professionalSurname,
      professionalDisplayName,
    } = doctor;

    if (
      !(
        professionalTitle?.trim() ||
        professionalName?.trim() ||
        professionalSurname?.trim()
      )
    ) {
      return professionalDisplayName.toLowerCase();
    }

    const formattedName = `${professionalTitle} ${professionalName} ${professionalSurname}`;
    return formattedName.toLowerCase();
  }

  function buildProfessionalSexData(sexChar: string | null) {
    if (sexChar === null || sexChar === undefined) {
      return {
        label: null,
        icon: null,
      };
    }

    switch (sexChar.toLowerCase()) {
      case "f":
        return {
          label: t("common.female"),
          icon: "FemaleSexIcon",
        };
      case "m":
        return {
          label: t("common.male"),
          icon: "MaleSexIcon",
        };
      default:
        return {
          label: null,
          icon: null,
        };
    }
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body1">
        {t("FindADoctor.suburbCityLabel")}
      </Typography>
      <Stack spacing={2}>
        {filteredTowns &&
          filteredTowns.map((town, index) => (
            <Stack key={String(town) + String(index)}>
              <DefaultAccordion
                title={town}
                expandedIconSize="large"
                expandedIconColour={theme.palette.neutral.dark}
                accordionStyles={{
                  textTransform: "capitalize",
                }}
                icon={
                  <ResultsIconCounter
                    resultsLength={
                      filterDoctorSearchResultsByTown(doctorSearchResults, town)
                        .length
                    }
                  />
                }
              >
                <Stack spacing={3}>
                  {filterDoctorSearchResultsByTown(
                    doctorSearchResults,
                    town
                  ).map((doctorDetails: any, index: number) => (
                    <Stack
                      boxShadow={shadows.light}
                      p={2}
                      spacing={2}
                      borderRadius={theme.shape.borderRadius}
                      key={String(doctorDetails.facilityTel) + String(index)}
                    >
                      <Stack
                        justifyContent="space-between"
                        direction={{ xs: "column", sm: "row" }}
                        alignItems={{ xs: "left", sm: "start" }}
                      >
                        <Stack spacing={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            {buildDoctorName(doctorDetails)}
                          </Typography>

                          <Typography variant="body2">
                            {doctorDetails.doctorType}
                          </Typography>
                        </Stack>
                        <Stack justifyContent={"row"}>
                          {isMobileDevice() === true && (
                            <Button
                              variant="outlined"
                              size="small"
                              startIcon={
                                <IconLoader
                                  icon="ShareIcon"
                                  sx={{ color: theme.palette.neutral.dark }}
                                />
                              }
                              sx={{
                                color: theme.palette.neutral.dark,
                                borderColor: theme.palette.neutral.dark,
                                fontSize: convertPxToRem(12),
                              }}
                              onClick={() =>
                                onShareDoctorInformationClick(doctorDetails)
                              }
                            >
                              {t("common.shareButtonText")}
                            </Button>
                          )}

                          {buildProfessionalSexData(
                            doctorDetails.professionalGender
                          )?.icon !== null &&
                            buildProfessionalSexData(
                              doctorDetails.professionalGender
                            ) && (
                              <Typography
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                color={theme.palette.grey[700]}
                                bgcolor={theme.palette.grey[300]}
                                variant="body1"
                                px={1}
                                py={0.5}
                                fontWeight={600}
                                fontSize={convertPxToRem(12)}
                                my={1}
                                borderRadius={theme.shape.borderRadius}
                              >
                                <IconLoader
                                  icon={
                                    buildProfessionalSexData(
                                      doctorDetails.professionalGender
                                    )?.icon || ""
                                  }
                                  sx={{
                                    mr: 0.5,
                                    fontSize: 16,
                                    color: theme.palette.neutral.dark,
                                  }}
                                />

                                {
                                  buildProfessionalSexData(
                                    doctorDetails.professionalGender
                                  )?.label
                                }
                              </Typography>
                            )}
                        </Stack>
                      </Stack>
                      <Divider />
                      <Stack direction="row" gap={1} alignItems="flex-start">
                        <WatermarkIcon
                          height={35}
                          width={35}
                          iconSize="medium"
                          color={theme.palette.primary[100]}
                          foreground={theme.palette.primary[700]}
                          Icon={"MapMarkerIcon"}
                        />
                        <Typography
                          component="a"
                          href={buildMapLinkURL(
                            `${doctorDetails.latitude},${doctorDetails.longitude}`
                          )}
                          target="_blank"
                          sx={{
                            ...overrideDefaultLinkStyling,
                            color: theme.palette.primary.main,
                            fontWeight: 600,
                          }}
                        >
                          {doctorDetails.address}
                        </Typography>
                      </Stack>

                      <Stack direction="row" gap={1} alignItems="center">
                        <WatermarkIcon
                          height={35}
                          width={35}
                          iconSize="medium"
                          color={theme.palette.primary[100]}
                          foreground={theme.palette.primary[700]}
                          Icon={"PhoneIcon"}
                        />
                        <Typography
                          component="a"
                          href={`tel:${doctorDetails.facilityTel}`}
                          sx={{
                            ...overrideDefaultLinkStyling,
                            color: theme.palette.primary.main,
                            fontWeight: 900,
                          }}
                        >
                          {doctorDetails.facilityTel}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </DefaultAccordion>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}
