import { useEffect } from "react";
import {
  trackPageViewV2,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import theme, { panelStyle } from "@/theme";
import { convertPxToRem } from "@/utils";
import { Stack, Typography, Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import CircledBackButton from "@/features/Common/components/CircledBackButton";
import IconLoader from "@/features/Common/components/IconLoader";

interface ActiveConsultationsProps {
  onDirectToDoctorConsultClick: () => void;
  onNurseConsultClick: () => void;
}

export function ConsultationTypeSelection({
  onDirectToDoctorConsultClick,
  onNurseConsultClick,
}: ActiveConsultationsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onBackButtonClick() {
    navigate(-1);
  }

  function onDirectToDoctorConsultationTypeClick() {
    trackUserInteraction({
      linkText: "ConsultationTypeSelection | Doctor on demand",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onDirectToDoctorConsultClick();
  }

  function onNurseConsultationTypeClick() {
    trackUserInteraction({
      linkText: "ConsultationTypeSelection | Nurse consult",
      linkIntent: "navigational",
      linkScope: "button",
    });
    onNurseConsultClick();
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Consultation type selection",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Consultation type selection",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <Stack
      color="neutral.dark"
      sx={{
        minHeight: "100%",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <Stack p={2} spacing={2}>
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        </Stack>
        <Stack>
          <IconLoader
            icon="ChatHeartIcon"
            color="primary"
            sx={{
              fontSize: convertPxToRem(56),
              alignSelf: "center",
            }}
          />
          <Typography textAlign="center" variant="h1" color="inherit">
            {t("ConsultationTypeSelection.title")}
          </Typography>

          <Stack spacing={4} py={4}>
            <Stack
              component="button"
              sx={{
                cursor: "pointer",
                border: "none",
              }}
              aria-label={t("ConsultationTypeSelection.DirectToDoctor.title")}
              color="neutral"
              onClick={onDirectToDoctorConsultationTypeClick}
            >
              <Box
                padding={4}
                textAlign="left"
                sx={{ ...panelStyle, position: "relative", width: "100%" }}
              >
                <Stack
                  sx={{
                    backgroundColor: "accent1.main",
                    borderBottomLeftRadius: 5,
                  }}
                  position="absolute"
                  top={0}
                  right={0}
                  padding={1}
                >
                  <Typography
                    fontWeight={600}
                    variant="body2"
                    color="primary.contrastText"
                  >
                    {t("common.mostPopular")}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body2"
                        color="neutral.light"
                        fontWeight={600}
                      >
                        {t("ConsultationTypeSelection.DirectToDoctor.subtitle")}
                      </Typography>
                      <Typography variant="h3" fontWeight={600}>
                        {t("ConsultationTypeSelection.DirectToDoctor.title")}
                      </Typography>
                    </Stack>
                    <Box
                      textAlign="center"
                      sx={{
                        background: (theme) => theme.palette.primary.main,
                        width: 90,
                        borderRadius: 2,
                        padding: 1,
                      }}
                    >
                      <Typography color="primary.contrastText" fontWeight={600}>
                        {t("ConsultationTypeSelection.DirectToDoctor.amount")}
                      </Typography>
                    </Box>
                  </Stack>
                  <IconLoader
                    sx={{ fontSize: 30 }}
                    color="primary"
                    icon="ChevronNextIcon"
                  />
                </Stack>
              </Box>
            </Stack>

            <Stack display="flex" position="relative">
              <Divider sx={{ borderWidth: ".5px", bgcolor: "primary" }} />
              <Typography
                variant="body2"
                fontWeight={600}
                fontSize={convertPxToRem(10)}
                sx={{
                  top: "50%",
                  left: "50%",
                  textAlign: "center",
                  transform: "translate(-50%, -50%)",
                  position: "absolute",
                  zIndex: 1,
                  p: 0.5,
                  borderRadius: theme.shape.borderRadius,
                  borderColor: (theme) => theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  borderWidth: 1,
                  borderStyle: "solid",
                  background: () => `${theme.palette.accent2[100]}`,
                  width: 35,
                }}
              >
                {t("ActiveConsultations.dividerLabel")}
              </Typography>
            </Stack>

            <Stack
              component="button"
              sx={{
                cursor: "pointer",
                border: "none",
              }}
              aria-label={t("ConsultationTypeSelection.nurseConsult.title")}
              color="neutral"
              onClick={onNurseConsultationTypeClick}
            >
              <Box
                padding={4}
                textAlign="left"
                sx={{ ...panelStyle, width: "100%" }}
              >
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                >
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      <Typography
                        variant="body2"
                        color="neutral.light"
                        fontWeight={600}
                      >
                        {t("ConsultationTypeSelection.nurseConsult.subtitle")}
                      </Typography>
                      <Typography variant="h3" fontWeight={600}>
                        {t("ConsultationTypeSelection.nurseConsult.title")}
                      </Typography>
                    </Stack>
                    <Box
                      textAlign="center"
                      sx={{
                        background: (theme) => theme.palette.primary.main,
                        width: 90,
                        borderRadius: 2,
                        padding: 1,
                      }}
                    >
                      <Typography color="primary.contrastText" fontWeight={600}>
                        {t("ConsultationTypeSelection.nurseConsult.amount")}
                      </Typography>
                    </Box>
                  </Stack>
                  <IconLoader
                    sx={{ fontSize: 30 }}
                    color="primary"
                    icon="ChevronNextIcon"
                  />
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
