import FullscreenBackground from "@/features/Common/components/FullscreenBackground";
import IconLoader from "@/features/Common/components/IconLoader";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import { UserRating } from "@/services/core-api-adapter";
import theme from "@/theme";
import { StarIcon } from "@/theme/icons";
import { convertPxToRem } from "@/utils";
import { TextareaAutosize as TextArea } from "@mui/base/TextareaAutosize";
import { Button, Rating, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface UserRatingFeedbackProps {
  onSendFeedback: (userRating: UserRating) => void;
  onSkipFeedback: () => void;
  serviceType: string;
  ratedServiceId: string;
}

export default function UserRatingFeedback({
  onSendFeedback,
  onSkipFeedback,
  serviceType,
  ratedServiceId,
}: UserRatingFeedbackProps) {
  const { t } = useTranslation();
  const [ratingValue, setRatingValue] = useState<number>(0);
  const [feedbackMessageValue, setFeedbackMessageValue] = useState("");

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:User rating feedback",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:User rating feedback",
      pageCategory: "Medical assistance",
    });
  }, []);

  function onSendFeedbackButtonClick() {
    onSendFeedback({
      rating: ratingValue,
      comment: feedbackMessageValue,
      serviceType: serviceType,
      ratedServiceId: ratedServiceId,
    });
  }

  function onSkipButtonClick() {
    onSkipFeedback();
  }

  return (
    <>
      <Stack
        justifyContent="flex-end"
        color="primary.contrastText"
        p={2}
        spacing={4}
        sx={{
          height: "100%",
        }}
      >
        <IconLoader
          icon="ChatWithRatingIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />

        <Stack textAlign="center" spacing={4} py={4}>
          <Stack>
            {(
              t<any, any, string[]>(`UserRatingFeedback.title`, {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Typography
                key={String(text) + String(index)}
                variant="h1"
                color="inherit"
              >
                {text}
              </Typography>
            ))}
          </Stack>

          <Typography variant="h3" color="inherit">
            {t("UserRatingFeedback.subTitle")}
          </Typography>
        </Stack>

        <Stack>
          <Stack spacing={1} my={2}>
            <Typography
              fontSize={convertPxToRem(12)}
              color="inherit"
              variant="body1"
            >
              {t("UserRatingFeedback.ratingWidget.title")}
            </Typography>

            <Rating
              emptyIcon={
                <StarIcon
                  style={{
                    color: theme.palette.neutral[50],
                    marginLeft: 2,
                    marginRight: 6,
                    fontSize: 50,
                  }}
                  fontSize="inherit"
                />
              }
              name={`userRatingInput`}
              sx={{
                width: "max-content",
                "& .MuiRating-iconFilled": {
                  color: theme.palette.neutral[50],
                  fontSize: 50,
                  marginRight: 1,
                },
              }}
              size="large"
              value={ratingValue}
              onChange={(_, newValue: any) => {
                setRatingValue(newValue);
              }}
            />
          </Stack>

          <Stack my={2}>
            <Typography
              fontSize={convertPxToRem(12)}
              color="inherit"
              variant="body1"
            >
              {t("UserRatingFeedback.ratingWidget.commentLabel")}
            </Typography>
            <TextArea
              style={{
                marginTop: 8,
                borderRadius: theme.shape.borderRadius,
                width: "100%",
                border: "none",
                padding: 4,
                fontFamily: "inherit",
                color: theme.palette.grey[700],
              }}
              onChange={(e: any) => {
                setFeedbackMessageValue(e.target.value);
              }}
              aria-label={t("UserRatingFeedback.ratingWidget.commentLabel")}
              minRows={6}
            />
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button
            onClick={onSkipButtonClick}
            color="info"
            variant="outlined"
            fullWidth
          >
            {t("UserRatingFeedback.skipButtonLabel")}
          </Button>
          <Button
            disabled={!ratingValue}
            onClick={onSendFeedbackButtonClick}
            color="info"
            fullWidth
            sx={{
              "&.Mui-disabled": {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[300],
              },
            }}
          >
            <Typography
              variant="body1"
              fontWeight="inherit"
              color={!ratingValue ? "grey.500" : "primary"}
            >
              {t("UserRatingFeedback.sendFeedbackButtonLabel")}
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <FullscreenBackground color="primary.main" />
    </>
  );
}
