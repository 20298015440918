import { useMachine } from "@xstate/react";
import { eventNames, intercareFlowStateMachine } from "./machine";
import { Button, Stack, Typography } from "@mui/material";
import { MedicalAssistanceDependantSelection } from "../../../ChatForMedicalAssistance/components/MedicalAssistanceDependantSelection";
import {
  GetMemberConsultationsItem,
  Member,
  MemberConsultationOutcome,
  UserRating,
  ServiceRating,
} from "../../../../services/core-api-adapter";
import { Navigate, useParams } from "react-router";
import DefaultError from "../../../Common/components/DefaultError";
import { useTranslation } from "react-i18next";
import { NurseConsultPrompt } from "../NurseConsultPrompt";
import { ActiveConsultations } from "../ActiveConsultations";
import { useEffect, useState } from "react";
import { ActiveConsultationOutstandingPayment } from "../ActiveConsultationOutstandingPayment";
import FindADoctor from "../FindADoctor";
import MedicalAssistanceFindAHospital from "../MedicalAssistanceFindAHospital";
import UserRatingFeedback from "../../../Common/components/UserRatingFeedback";
import UserRatingFeedbackError from "../../../Common/components/UserRatingFeedbackError";
import ThankYou from "../../../Common/components/ThankYou";
import NurseConsultMoreInfo from "../NurseConsultMoreInfo";
import { Refund } from "../Refund";
import OutsideConsultationOperatingHours from "../OutsideConsultationOperatingHours";
import PaymentFailure from "../../../Common/components/PaymentFailure";
import FullscreenLoadingIndicator from "../../../Common/components/FullscreenLoadingIndicator";
import {
  AnalyticsEvent,
  trackApplicationEvent,
  trackEvent,
} from "@/services/analytics-adapter";
import DoctorBookingConfirmation from "../DoctorBookingConfirmation";
import Breakout from "../Breakout";
import { DirectToDoctorPrompt } from "../DirectToDoctorPrompt";
import { ConsultationTypeSelection } from "../ConsultationTypeSelection";
import { useSearchParams } from "react-router-dom";
import MaxWidthContainer from "../../../Common/components/MaxWidthContainer";
import AvailablePromoCodesModal from "@/features/Common/components/AvailablePromoCodesModal";

const UNU_HEALTH_API_BASE_URI =
  import.meta.env.VITE_APP_UNU_HEALTH_API_BASE_URI || "";

export default function InterCareMedicalAssistanceFlow() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const primarySymptomIds = searchParams.get("primarySymptomIds");
  const [stateMachineState, dispatchStateMachineEvent] = useMachine(
    intercareFlowStateMachine,
    {
      input: {
        context: {
          queryParamConsultationId: id || "",
          primarySymptomIds: primarySymptomIds?.split(",") || [],
        },
      },
    } as any
  );

  function onDependentSelectionNextButtonClick(selectedMember: Member) {
    dispatchStateMachineEvent({
      type: eventNames.DEPENDANT_SELECTED,
      input: selectedMember,
    });
  }

  function onBackButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.BACK_BUTTON_CLICKED });
  }

  function onGoHomeButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.GO_HOME_BUTTON_CLICKED });
  }

  function getPaymentRedirectUrl() {
    const consultationId =
      stateMachineState.context.selectedConsultation?.consultationId;
    const redirectURL = `${UNU_HEALTH_API_BASE_URI}/ecentric/get-payment-page?ConsultationId=${consultationId}`;

    return redirectURL;
  }

  function onOutstandingPaymentContinueButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.USER_REDIRECTED });
  }

  function onActiveConsultationSelected(
    consultation: GetMemberConsultationsItem
  ) {
    dispatchStateMachineEvent({
      type: eventNames.ACTIVE_CONSULTATION_SELECTED,
      input: consultation,
    });

    if (
      consultation.outcome ===
        MemberConsultationOutcome.DIGITAL_CONSULT_STARTED ||
      consultation.outcome === MemberConsultationOutcome.TRIAGE_STARTED ||
      consultation.outcome === MemberConsultationOutcome.SESSION_STARTED ||
      consultation.outcome === MemberConsultationOutcome.TRIAGE_ENDED ||
      consultation.outcome === MemberConsultationOutcome.PAYMENT_SUCCEEDED ||
      consultation.outcome === MemberConsultationOutcome.NO_REFUND
    ) {
      dispatchStateMachineEvent({ type: eventNames.USER_REDIRECTED });
    }
  }

  function onActiveConsultationsStartNewConsultation() {
    dispatchStateMachineEvent({
      type: eventNames.START_CONSULTATION_BUTTON_CLICKED,
    });
  }

  function onContinueButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.CONTINUE_BUTTON_CLICKED });
  }

  function onPromptContinueButtonClick() {
    trackEvent({
      event: "action.nextStepsContinue",
      source: "Medical Assistance",
    });
    onContinueButtonClick();
  }

  function onDirectToDoctorPromptContinueButtonClick() {
    onContinueButtonClick();
  }

  function onPromptBackButtonClick() {
    trackEvent({
      event: "action.nextStepsBack",
      source: "Medical Assistance",
    });
    onBackButtonClick();
  }

  function onRatingTryAgain() {
    trackEvent({
      event: "action.submitRatingRetry",
      source: "Medical Assistance",
    });
    dispatchStateMachineEvent({ type: eventNames.RATING_TRY_AGAIN });
  }

  function onRatingDoneButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.CONTINUE_BUTTON_CLICKED });
  }

  function onSendRatingFeedback(userRating: UserRating) {
    dispatchStateMachineEvent({
      type: eventNames.CONTINUE_BUTTON_CLICKED,
      input: userRating,
    });
  }

  function onSkipRatingFeedback() {
    trackEvent({
      event: "action.submitRatingSkipped",
      source: "Medical Assistance",
    });
    dispatchStateMachineEvent({ type: eventNames.GO_HOME_BUTTON_CLICKED });
  }

  function onRefundContinueButtonClick() {
    dispatchStateMachineEvent({ type: eventNames.CONTINUE_BUTTON_CLICKED });
  }

  function trackEventBasedOnState(stateMachineState: any) {
    const statesToTrack = [
      "outsideOperatingHours",
      "submitRatingError",
      "submitRatingComplete",
      "selectingDependents",
      "outstandingPayment",
      "outstandingRefund",
      "paymentFailure",
      "findADoctor",
      "findAHospital",
    ];

    const stateMachineStateValue = stateMachineState.value;

    if (statesToTrack.includes(stateMachineStateValue)) {
      trackEvent({
        event: `action.${stateMachineStateValue}`,
        source: "Medical Assistance",
      });
    }
  }

  function getBreakoutDisclaimerRedirectUrl() {
    let redirectURL;
    if (stateMachineState.context.queryParamConsultationId) {
      redirectURL =
        stateMachineState.context.consultationOutcomeResponse?.redirectUrl;
    } else {
      redirectURL = stateMachineState.context.selectedConsultation?.redirectUrl;
    }

    return redirectURL;
  }

  function onBreakoutDisclaimerContinue() {
    dispatchStateMachineEvent({ type: eventNames.USER_REDIRECTED });
  }

  function onSelectDirectToDoctor() {
    dispatchStateMachineEvent({
      type: eventNames.DIRECT_TO_DOCTOR_BUTTON_CLICKED,
    });
  }

  function onSelectNurseConsult() {
    dispatchStateMachineEvent({
      type: eventNames.NURSE_CONSULTATION_BUTTON_CLICKED,
    });
  }

  function onFindADoctorButtonClick() {
    dispatchStateMachineEvent({
      type: eventNames.FIND_A_DOCTOR_BUTTON_CLICKED,
    });
  }

  useEffect(() => {
    setIsLoading(
      stateMachineState.matches("fetchingConsultationOutcome") ||
        stateMachineState.matches("startingConsultation") ||
        stateMachineState.matches("waitingForConsultationOutcome") ||
        stateMachineState.matches("processingConsultationOutcome")
    );
    trackEventBasedOnState(stateMachineState);

    if (stateMachineState.matches("start")) {
      trackApplicationEvent(AnalyticsEvent.APPLICATION_START, {
        applicationName: "Application: intercare medical assistance",
        applicationStep: "step 1",
      });
    }

    if (stateMachineState.matches("processingConsultationOutcome")) {
      trackApplicationEvent(AnalyticsEvent.APPLICATION_COMPLETE, {
        applicationName: "Application: intercare medical assistance",
        applicationStep: "step 3",
      });
    }
  }, [stateMachineState, stateMachineState.context]);

  return (
    <MaxWidthContainer>
      <Stack
        spacing={2}
        justifyContent="space-between"
        sx={{ minHeight: "100%", overflowX: "hidden" }}
      >
        {stateMachineState.matches("promptingNurseConsultNextSteps") && (
          <NurseConsultPrompt
            onContinueButtonClick={onPromptContinueButtonClick}
            onBackButtonClick={onPromptBackButtonClick}
          />
        )}

        {stateMachineState.matches("promptingDirectToDoctorNextSteps") && (
          <DirectToDoctorPrompt
            onContinueButtonClick={onDirectToDoctorPromptContinueButtonClick}
            onBackButtonClick={onPromptBackButtonClick}
            onFindADoctorButtonClick={onFindADoctorButtonClick}
          />
        )}

        {(stateMachineState.matches("selectConsultationType") ||
          stateMachineState.matches("promptingPromoCode")) && (
          <>
            <ConsultationTypeSelection
              onDirectToDoctorConsultClick={onSelectDirectToDoctor}
              onNurseConsultClick={onSelectNurseConsult}
            />
          </>
        )}

        {stateMachineState.matches("promptingPromoCode") &&
          stateMachineState.context.memberServiceOfferingUsages && (
            <AvailablePromoCodesModal
              serviceOfferingUsageItems={
                stateMachineState.context.memberServiceOfferingUsages
              }
              isModalOpen={true}
              onContinueButtonClick={onContinueButtonClick}
              onSkipButtonClick={() =>
                dispatchStateMachineEvent({
                  type: eventNames.SKIP_BUTTON_CLICKED,
                })
              }
              onModalClose={onBackButtonClick}
            />
          )}

        {stateMachineState.matches("promptingNurseConsultMoreInfo") && (
          <NurseConsultMoreInfo onBackButtonClick={onBackButtonClick} />
        )}

        {stateMachineState.matches("outstandingPayment") && (
          <ActiveConsultationOutstandingPayment
            priceFormatted={
              stateMachineState.context.selectedConsultation
                ?.chargeAmountFormatted
            }
            redirectUrl={getPaymentRedirectUrl()}
            chargeCode={
              stateMachineState.context.selectedConsultation?.chargeCode
            }
            onContinue={onOutstandingPaymentContinueButtonClick}
            onBack={onBackButtonClick}
          />
        )}

        {stateMachineState.matches("outstandingRefund") && (
          <Refund
            priceFormatted={
              stateMachineState.context.selectedConsultation
                ?.chargeAmountFormatted
            }
            onContinue={onRefundContinueButtonClick}
            onBack={onBackButtonClick}
          />
        )}

        {stateMachineState.matches("paymentFailure") && (
          <PaymentFailure onContinue={onGoHomeButtonClick} />
        )}

        {stateMachineState.matches("selectingDependents") && (
          <Stack p={2} height="100vh" justifyContent="end">
            <MedicalAssistanceDependantSelection
              onNextButtonClick={onDependentSelectionNextButtonClick}
            />
          </Stack>
        )}

        {stateMachineState.matches("selectingActiveConsultation") && (
          <ActiveConsultations
            consultations={
              stateMachineState.context.activeConsultationListResponse
            }
            availabilityData={
              stateMachineState.context.consultationAvailabilityData
            }
            onActiveConsultationSelected={onActiveConsultationSelected}
            onStartNewConsultation={onActiveConsultationsStartNewConsultation}
          />
        )}

        {stateMachineState.matches("genericError") && (
          <Stack height="100vh" justifyContent="end">
            <DefaultError onComeBackLater={onGoHomeButtonClick}>
              <Typography variant="h2">{t("GenericError.title")}</Typography>
              <Typography pb={2}>{t("GenericError.content")}</Typography>
            </DefaultError>
          </Stack>
        )}

        {stateMachineState.matches("outsideOperatingHours") && (
          <Stack sx={{ height: "100vh" }}>
            <OutsideConsultationOperatingHours
              availabilityData={
                stateMachineState.context.consultationAvailabilityData
              }
              shouldDisplayViewConsultationHistoryCTA={
                stateMachineState.context.selectedConsultation?.outcome ===
                MemberConsultationOutcome.PAYMENT_SUCCEEDED
              }
              redirectUrl={
                stateMachineState.context.selectedConsultation?.redirectUrl
              }
            />
          </Stack>
        )}

        {stateMachineState.matches("promptingFindADoctor") && (
          <Stack sx={{ height: "100vh" }}>
            <DoctorBookingConfirmation
              onGoBackHome={onBackButtonClick}
              onContinue={onContinueButtonClick}
            />
          </Stack>
        )}

        {stateMachineState.matches("findADoctor") && (
          <Stack sx={{ minHeight: "100vh" }}>
            <FindADoctor onBackButton={onBackButtonClick} />
          </Stack>
        )}

        {stateMachineState.matches("findAHospital") && (
          <Stack sx={{ minHeight: "100vh" }}>
            <MedicalAssistanceFindAHospital />
          </Stack>
        )}

        {stateMachineState.matches("serviceRating") && (
          <Stack sx={{ minHeight: "100vh" }}>
            <UserRatingFeedback
              onSkipFeedback={onSkipRatingFeedback}
              serviceType={ServiceRating.CONSULTATION}
              ratedServiceId={
                stateMachineState.context.selectedConsultation?.consultationId
              }
              onSendFeedback={onSendRatingFeedback}
            />
          </Stack>
        )}

        {stateMachineState.matches("submitRatingError") && (
          <UserRatingFeedbackError onTryAgain={onRatingTryAgain} />
        )}

        {stateMachineState.matches("submitRatingComplete") && (
          <ThankYou
            title={t("UserRatingFeedback.thankYou.title")}
            subTitle={t("UserRatingFeedback.thankYou.subtitle")}
            buttonLabel={t("common.backHomeButton")}
            onButtonClick={onRatingDoneButtonClick}
          />
        )}

        {stateMachineState.matches("exit") && <Navigate to="/" />}

        {stateMachineState.matches("fetchingMemberDetails") && (
          <Stack height="100%">
            <FullscreenLoadingIndicator mainIconType="face" />
          </Stack>
        )}

        {stateMachineState.matches("breakoutDisclaimer") && (
          <Stack sx={{ height: "98vh" }}>
            <Breakout
              onConfirm={onBreakoutDisclaimerContinue}
              redirectUrl={getBreakoutDisclaimerRedirectUrl()}
              onDecline={onBackButtonClick}
            />
          </Stack>
        )}
        {isLoading && (
          <Stack height="100%">
            <FullscreenLoadingIndicator
              loadingText={t("common.pleaseWait")}
              mainIconType="face"
            >
              <Stack pt={5}>
                <Button
                  variant="outlined"
                  onClick={onGoHomeButtonClick}
                  color="primary"
                  fullWidth
                >
                  {t("common.goHomeButton")}
                </Button>
              </Stack>
            </FullscreenLoadingIndicator>
          </Stack>
        )}
      </Stack>
    </MaxWidthContainer>
  );
}
