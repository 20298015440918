import { Trans, useTranslation } from "react-i18next";
import SlideUpDialog from "../SlideUpDialog";
import { Checkbox, Link, ListItem, Stack, Typography } from "@mui/material";
import IconLoader from "../IconLoader";
import ButtonWithAnalytics from "../ButtonWithAnalytics";
import { convertPxToRem, formatDateToReadableString } from "@/utils";
import { useState } from "react";
import { MemberServiceOfferingUsage } from "@/services/core-api-adapter";

interface AvailablePromoCodesModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
  onContinueButtonClick: () => void;
  onSkipButtonClick: () => void;
  serviceOfferingUsageItems: MemberServiceOfferingUsage[];
}

export default function AvailablePromoCodesModal({
  isModalOpen,
  onModalClose,
  onContinueButtonClick,
  onSkipButtonClick,
  serviceOfferingUsageItems,
}: AvailablePromoCodesModalProps) {
  const { t } = useTranslation();
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);

  function onTermsAndConditionsCheckboxChange(e: any) {
    e.preventDefault();
    setIsTermsAndConditionsAccepted(e.target.checked);
  }

  return (
    <SlideUpDialog
      isOpen={isModalOpen}
      onClose={() => onModalClose()}
      label={t("AvailablePromoCodesModal.title")}
    >
      <Stack textAlign="center" spacing={3}>
        <IconLoader
          icon="TagWithCheckIcon"
          color="primary"
          sx={{ fontSize: convertPxToRem(90), alignSelf: "center" }}
        />

        <Stack spacing={1}>
          <Typography variant="h3" fontWeight={600}>
            {t("AvailablePromoCodesModal.title")}
          </Typography>

          <Typography variant="h4" fontWeight={400}>
            {t("AvailablePromoCodesModal.subtitle")}
          </Typography>
        </Stack>

        <Stack bgcolor="accent2.lightest" borderRadius={2} my={2} p={2}>
          <Stack
            component="ul"
            role="list"
            aria-label={t("MedicalAssistanceV2.consultationOptionsLabel")}
          >
            {serviceOfferingUsageItems.map(
              (serviceOfferingUsageItem, index) => (
                <ListItem
                  role="listitem"
                  key={serviceOfferingUsageItem.id + index}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack direction="row" spacing={1}>
                      <IconLoader icon="CheckVerifiedIcon" color="primary" />
                      <Typography>
                        <Trans
                          i18nKey={
                            `AvailablePromoCodesModal.${serviceOfferingUsageItem.serviceOfferingType}.promo.title` as any
                          }
                        ></Trans>
                      </Typography>
                    </Stack>
                    <Stack spacing={0.5} textAlign="right">
                      <Typography variant="subtitle1" color="neutral.main">
                        {t("AvailablePromoCodesModal.validUntil.title")}
                      </Typography>
                      <Typography variant="subtitle1">
                        {formatDateToReadableString(
                          new Date(serviceOfferingUsageItem.availableTo)
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                </ListItem>
              )
            )}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Checkbox
            inputProps={{
              "aria-label": t(
                "AvailablePromoCodesModal.termsAndConditions.link.title"
              ),
            }}
            sx={{ padding: 0 }}
            value={isTermsAndConditionsAccepted}
            onChange={onTermsAndConditionsCheckboxChange}
          />
          <Typography>
            <Trans i18nKey="AvailablePromoCodesModal.termsAndConditions.link.title">
              <Link href="/" onClick={() => {}}></Link>
            </Trans>
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <ButtonWithAnalytics
            intent="confirmational"
            page="AvailablePromoCodes"
            text={t("AvailablePromoCodesModal.useLater.CTA.title")}
            fullWidth
            variant="outlined"
            onClick={onSkipButtonClick}
          >
            {t("AvailablePromoCodesModal.useLater.CTA.title")}
          </ButtonWithAnalytics>
          <ButtonWithAnalytics
            disabled={isTermsAndConditionsAccepted === false}
            onClick={onContinueButtonClick}
            intent="confirmational"
            page="AvailablePromoCodes"
            text={t("common.yesPlease")}
            fullWidth
          >
            {t("common.yesPlease")}
          </ButtonWithAnalytics>
        </Stack>
      </Stack>
    </SlideUpDialog>
  );
}
