import CircledBackButton from "@/features/Common/components/CircledBackButton";
import IconLoader from "@/features/Common/components/IconLoader";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import theme from "@/theme";
import { convertPxToRem } from "@/utils";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface RefundProps {
  priceFormatted: string | undefined | null;
  onContinue: () => void;
  onBack: () => void;
}

export function Refund({ priceFormatted, onContinue, onBack }: RefundProps) {
  const { t } = useTranslation();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Refund issued",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Refund issued",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <Stack
      color="neutral.dark"
      sx={{
        minHeight: "100%",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <Box p={2}>
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBack} />
        </Stack>
        <Stack>
          <IconLoader
            icon="ChatWithHandIcon"
            color="primary"
            sx={{
              fontSize: convertPxToRem(56),
              alignSelf: "center",
            }}
          />
          <Stack textAlign="center" spacing={2} py={4}>
            <Typography variant="h1" color="inherit">
              {t("Refund.title")}
            </Typography>
            {(
              t<any, any, string[]>("Refund.subTitle", {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Typography
                variant="body1"
                color="inherit"
                key={String(text + index)}
              >
                {text}
              </Typography>
            ))}
          </Stack>
          <Stack
            bgcolor="accent2.lightest"
            p={2}
            borderRadius={theme.shape.borderRadius}
            my={2}
            spacing={1}
          >
            <Typography fontWeight={600} textAlign="center" variant="h3">
              {t("Refund.amountDueLabel")}
            </Typography>
            <Typography textAlign="center" variant="h1" color="primary">
              {priceFormatted}
            </Typography>
          </Stack>
        </Stack>
        <Stack my={3}>
          <Typography textAlign="center" variant="body2">
            {t("Refund.noteToUser")}
          </Typography>
        </Stack>
        <Button onClick={onContinue} color="primary" fullWidth>
          {t("common.goHomeButton")}
        </Button>
      </Box>
    </Stack>
  );
}
