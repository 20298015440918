import { useState } from "react";
import { Typography, Stack, SxProps, Theme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Trans } from "react-i18next";
import IconLoader from "../IconLoader";
import { trackUserInteraction } from "@/services/analytics-adapter";

interface Props {
  title: string;
  subtitle?: string | null;
  icon?: React.JSX.Element;
  expandedIcon?: React.JSX.Element;
  expandedIconSize?: "small" | "inherit" | "medium" | "large";
  expandedIconColour?: string | null;
  disabled?: boolean;
  expanded?: boolean;
  children?: React.ReactNode;
  accordionStyles?: SxProps<Theme>;
  titleFontWeight?: number;
  titleVariant?: "body1" | "body2" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  "aria-label"?: string;
  onExpand?: (expanded: boolean) => void;
}

export default function DefaultAccordion(props: Props) {
  const [isExpanded, setIsExpanded] = useState(props.expanded || false);

  function onChange(_: React.SyntheticEvent, expanded: boolean) {
    setIsExpanded(expanded);
    if (props.onExpand) {
      props.onExpand(expanded);
    }
    trackUserInteraction({
      linkText: `Accordion | ${props.title} ${isExpanded ? "collapsed" : "expanded"}`,
      linkIntent: "informational",
      linkScope: "accordion",
    });
  }

  return (
    <Accordion
      expanded={props.expanded}
      onChange={onChange}
      disabled={props.disabled}
      disableGutters={true}
      sx={props.accordionStyles}
    >
      <AccordionSummary
        expandIcon={
          <IconLoader
            icon="ChevronDownIcon"
            fontSize={props.expandedIconSize || "medium"}
            sx={{
              color: props?.expandedIconColour,
            }}
          />
        }
        sx={{ py: 2 }}
        aria-label={props["aria-label"] || props.title}
      >
        <Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            {props.expandedIcon && isExpanded ? props.expandedIcon : props.icon}

            <Typography
              variant={props.titleVariant || "body1"}
              fontWeight={props.titleFontWeight || "400"}
            >
              <Trans i18nKey={props.title as any}>{props.title}</Trans>
            </Typography>
          </Stack>

          {props.subtitle && (
            <Typography
              sx={{ paddingLeft: 5, textAlign: "left" }}
              variant="body2"
            >
              {props.subtitle}
            </Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>{props.children}</AccordionDetails>
    </Accordion>
  );
}
