import CircledBackButton from "@/features/Common/components/CircledBackButton";
import IconLoader from "@/features/Common/components/IconLoader";
import {
  trackPageViewV2,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import { MemberProductPaymentRatesChargeCode } from "@/services/core-api-adapter";
import theme from "@/theme";
import { convertPxToRem } from "@/utils";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/features/Common/components/ButtonWithAnalytics";

interface ActiveConsultationOutstandingPaymentProps {
  priceFormatted: string | null | undefined;
  redirectUrl: string | undefined;
  chargeCode: MemberProductPaymentRatesChargeCode | null | undefined;
  onContinue: () => void;
  onBack: () => void;
}

export function ActiveConsultationOutstandingPayment({
  priceFormatted,
  chargeCode,
  redirectUrl,
  onContinue,
  onBack,
}: ActiveConsultationOutstandingPaymentProps) {
  const { t } = useTranslation();

  function getChargeDescription() {
    if (chargeCode && chargeCode in MemberProductPaymentRatesChargeCode) {
      return t(
        `ActiveConsultationOutstandingPayment.${chargeCode}.amountDueSubLabel` as any
      );
    }
    return t("ActiveConsultationOutstandingPayment.amountDueSubLabel");
  }

  function onYesPleaseButtonClick() {
    trackUserInteraction({
      linkText: "ActiveConsultationOutstandingPayment | Yes, please",
      linkIntent: "confirmational",
      linkScope: "button",
    });
    onContinue();
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Outstanding payment",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Outstanding payment",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <Stack
      color="neutral.dark"
      p={2}
      spacing={4}
      sx={{
        minHeight: "100%",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <Stack>
        <CircledBackButton showLabel={true} onClick={onBack} />
      </Stack>
      <Stack>
        <IconLoader
          icon="ChatWithTimerIcon"
          color="error"
          sx={{
            fontSize: convertPxToRem(56),
            alignSelf: "center",
          }}
        />
        <Stack textAlign="center" spacing={2} py={4}>
          <Typography variant="h1" color="inherit">
            {t("ActiveConsultationOutstandingPayment.title")}
          </Typography>

          <Typography variant="body1" color="inherit" px={2}>
            {t("ActiveConsultationOutstandingPayment.subTitle")}
          </Typography>
        </Stack>
        <Stack
          bgcolor="accent2.lightest"
          p={2}
          borderRadius={theme.shape.borderRadius}
          my={2}
          spacing={1}
        >
          <Typography textAlign="center" variant="h2">
            {t("ActiveConsultationOutstandingPayment.amountDueLabel")}
          </Typography>
          <Typography textAlign="center" variant="body2">
            {getChargeDescription()}
          </Typography>
          <Typography textAlign="center" variant="h1" color="primary">
            {priceFormatted}
          </Typography>
        </Stack>
      </Stack>
      <Typography textAlign="center" variant="body1">
        {t("ActiveConsultationOutstandingPayment.wouldYouLikeToContinueLabel")}
      </Typography>

      <Stack direction="row" spacing={2}>
        <ButtonWithAnalytics
          page="ActiveConsultationOutstandingPayment"
          text={t("common.noThankYou")}
          intent="confirmational"
          onClick={onBack}
          color="primary"
          fullWidth
          variant="outlined"
        >
          {t("common.noThankYou")}
        </ButtonWithAnalytics>
        <Button
          component="a"
          target="_blank"
          href={redirectUrl}
          aria-label={t("common.yesPlease")}
          onClick={onYesPleaseButtonClick}
          color="primary"
          fullWidth
        >
          {t("common.yesPlease")}
        </Button>
      </Stack>
    </Stack>
  );
}
